import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContextProvider from "./AuthContext";
import Home from "./Home";
import Data from "./Data";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Select1 from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

axios.defaults.withCredentials = true;

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper2: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "green",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

function App() {
  const [schools, setSchools] = useState([]);
  const [classese, setClassese] = useState([]);
  const [streams, setStreams] = useState([]);
  const [years, setYears] = useState([]);
  const [aois, setAois] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.manipuredu.online/api/getSchoolsClassesStreamsBatchesAois",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSchools(data.schools);
        setClassese(data.classes);
        setStreams(data.streams);
        setYears(data.batches);
        setAois(data.aois);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [school, setSchool] = useState("");
  const [class_, setClass_] = useState("");
  const [stream, setStream] = useState("");
  const [year, setYear] = useState("");
  const [aoi, setAoi] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [question, setQuestion] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [whatsappErr, setWhatsappErr] = useState(false);
  const [schoolErr, setSchoolErr] = useState(false);
  const [class_Err, setClass_Err] = useState(false);
  const [streamErr, setStreamErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [aoiErr, setAoiErr] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [nameErrText, setNameErrText] = useState("");
  const [phoneErrText, setPhoneErrText] = useState("");
  const [whatsappErrText, setWhatsappErrText] = useState("");
  const [schoolErrText, setSchoolErrText] = useState("");
  const [classErrText, setClassErrText] = useState("");
  const [streamErrText, setStreamErrText] = useState("");
  const [yearErrText, setYearErrText] = useState("");
  const [aoiErrText, setAoiErrText] = useState("");
  const [streamSelector, setStreamSelector] = useState(true);

  const handleName = (value) => {
    setName(value);
  };
  const handlePhone = (value) => {
    setPhone(value);
  };
  const handleWhatsapp = (value) => {
    setWhatsapp(value);
  };

  const [otherSchool, setOtherSchool] = useState("");
  const [otherSchoolStatus, setOtherSchoolStatus] = useState(false);

  const handleSchool = (value) => {
    if (value == 203) {
      setSchool(value);
      setOtherSchoolStatus(true);
    } else {
      setSchool(value);
      setOtherSchoolStatus(false);
    }
  };

  const handleClass_ = (value) => {
    if (value == "1" || value == "2") {
      setStreamSelector(true);
      setClass_(value);
      setStream("");
    } else {
      setStreamSelector(false);
      setClass_(value);
      setStream("");
    }
  };

  const handleStream = (value) => {
    setStream(value);
  };
  const handleYear = (value) => {
    setYear(value);
  };

  const [otherAoi, setOtherAoi] = useState("");
  const [otherAoiStatus, setOtherAoiStatus] = useState(false);

  const handleAoi = (value) => {
    if (value == 2099999993) {
      setAoi(value);
      setOtherAoiStatus(true);
    } else {
      setAoi(value);
      setOtherAoiStatus(false);
    }
  };

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    if (
      name == "" ||
      (name.length > 1 && name.length < 5) ||
      phone == "" ||
      phone.length !== 10 ||
      whatsapp == "" ||
      whatsapp.length !== 10 ||
      school == "" ||
      class_ == "" ||
      year == "" ||
      aoi == ""
    ) {
      if (name == "") {
        alert("Please enter your Name");
      }

      if (name.length > 1 && name.length < 5) {
        alert("Please enter your Full Name");
      }

      if (phone == "") {
        alert("Please enter your Mobile number");
      }

      if (phone > 1 && phone.length !== 10) {
        alert("Please enter your 10 digit mobile number");
      }

      if (whatsapp == "") {
        alert("Please enter your Whatsapp number");
      }

      if (whatsapp > 1 && whatsapp.length !== 10) {
        alert("Please enter your 10 digit Whatsapp number");
      }

      if (school == "") {
        alert("Please select your School");
      }

      if (class_ == "") {
        alert("Please select your Class");
      }

      if (year == "") {
        alert("Please Select your Academic Session/Year");
      }

      if (aoi == "") {
        alert("Please select your Subject of Interests");
      }
    } else {
      if ((class_ == "3" && stream == "") || (class_ == "4" && stream == "")) {
        alert("Please select your Stream");
      } else {
        if (school == 203 && otherSchool == "") {
          alert("Please enter your School Name");
        } else {
          if (aoi == 203 && otherAoi == "") {
            alert("Please enter your Area of Interest");
          } else {
            setSubmit(true);
  
            const array = [
              name,
              phone,
              whatsapp,
              school,
              class_,
              stream,
              year,
              aoi,
              hobbies,
              question,
            ];
  
            fetch("https://api.manipuredu.online/api/counsellingFormSubmit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: name,
                phone: phone,
                whatsapp: whatsapp,
                school: school,
                otherSchool: otherSchool,
                class_: class_,
                stream: stream,
                year: year,
                aoi: aoi,
                hobbies: hobbies,
                question: question,
                otherAoi: otherAoi 
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                if (data == 1) {
                  setName("");
                  setPhone("");
                  setWhatsapp("");
                  setSchool("");
                  setOtherSchool("");
                  setOtherSchoolStatus(false);
                  setOtherAoi("");
                  setOtherAoiStatus(false);                  
                  setClass_("");
                  setYear("");
                  setAoi("");
                  setHobbies("");
                  setQuestion("");
                  setStream("");
                  setOpen(true);
                }
  
                setSubmit(false);
              })
              .catch((err) => {
                setSubmit(false);
                console.log(err);
              });
          }
        }
      }
    }
  };

  return (
    <div className="App">
      <div className="container">
        <Paper elevation={3} className="mt-3 pt-3">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="px-5 text-center">
                  <img src="/sslogonew.jpg" width="60px"></img>
                  <p style={{ fontWeight: "bold" }}>SS CAREER SOLUTION</p>
                  <p style={{ fontWeight: "bold" }}>Assessment Form</p>
                </div>
                <div></div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="px-5">
                    <div className="form-group" style={{ height: 40 }}>
                      <LightTooltip title="Example: Michael Nongmaithem">
                        <TextField
                          fullWidth
                          helperText={false}
                          variant="outlined"
                          error={nameErr}
                          margin="dense"
                          label="Student Name"
                          type="text"
                          size="small"
                          value={name}
                          onChange={(e) => handleName(e.target.value)}
                        />
                      </LightTooltip>
                    </div>
                    <div className="form-group" style={{ height: 40 }}>
                      <LightTooltip title="Example: 8704524565">
                        <TextField
                          fullWidth
                          helperText={false}
                          variant="outlined"
                          error={phoneErr}
                          margin="dense"
                          label="Phone Number"
                          type="number"
                          value={phone}
                          onChange={(e) => handlePhone(e.target.value)}
                        />
                      </LightTooltip>
                    </div>
                    <div className="form-group" style={{ height: 50 }}>
                      <LightTooltip title="Example: 8704524565">
                        <TextField
                          fullWidth
                          helperText={false}
                          variant="outlined"
                          error={whatsappErr}
                          margin="dense"
                          label="Whatsapp Number"
                          type="number"
                          value={whatsapp}
                          onChange={(e) => handleWhatsapp(e.target.value)}
                        />
                      </LightTooltip>
                    </div>
                    <div className="form-group" style={{ height: 40 }}>
                      {schools.length == 0 ? (
                        <div className="text-center">
                            <img className="img-fluid" src="/sscLoading.gif" width="60%" />                          
                        </div>
                      ) : (
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={schoolErr}
                          fullWidth
                        >
                          <InputLabel>School</InputLabel>
                          <Select1
                            label="School"
                            value={school}
                            onChange={(e) => handleSchool(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Select School</em>
                            </MenuItem>
                              <MenuItem
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                  value={203}
                                >
                                  {"OTHER"}
                            </MenuItem>
                            {schools.filter(school => school.name !== "OTHER").map(filteredSchool => (
                                <MenuItem value={filteredSchool.id}>
                                {filteredSchool.name}
                                </MenuItem>
                              ))
                            }
                          </Select1>
                          <FormHelperText>{false}</FormHelperText>
                        </FormControl>
                      )}
                    </div>
                    {otherSchoolStatus == true ? (
                      <div className="form-group" style={{ height: 50 }}>
                        <LightTooltip title="Example: 8704524565">
                          <TextField
                            fullWidth
                            helperText={false}
                            variant="outlined"
                            margin="dense"
                            label="Other School Name"
                            type="text"
                            value={otherSchool}
                            onChange={(e) => setOtherSchool(e.target.value)}
                          />
                        </LightTooltip>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group" style={{ height: 40 }}>
                      {classes.length == 0 ? (
                        <div className="text-center">
                            <img className="img-fluid" src="/sscLoading.gif" width="60%" />                         
                        </div>
                      ) : (
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={class_Err}
                          fullWidth
                        >
                          <InputLabel>Class</InputLabel>
                          <Select1
                            label="Class"
                            value={class_}
                            onChange={(e) => handleClass_(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {classese &&
                              classese.map((classe) => (
                                <MenuItem value={classe.id}>
                                  {classe.name}
                                </MenuItem>
                              ))}
                          </Select1>
                          <FormHelperText>{false}</FormHelperText>
                        </FormControl>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="px-5">
                    <div className="form-group" style={{ height: 40 }}>
                      {streams.length == 0 ? (
                        <div className="text-center">
                            <img className="img-fluid" src="/sscLoading.gif" width="60%" />                       
                        </div>
                      ) : (
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={streamErr}
                          fullWidth
                        >
                          <InputLabel>Stream</InputLabel>
                          <Select1
                            disabled={streamSelector}
                            label="Stream"
                            value={stream}
                            onChange={(e) => handleStream(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {streams &&
                              streams.map((stream) => (
                                <MenuItem value={stream.id}>
                                  {stream.name}
                                </MenuItem>
                              ))}
                          </Select1>
                          <FormHelperText>{false}</FormHelperText>
                        </FormControl>
                      )}
                    </div>
                    <div className="form-group" style={{ height: 40 }}>
                      {years.length == 0 ? (
                        <div className="text-center">
                            <img className="img-fluid" src="/sscLoading.gif" width="60%" />                           
                        </div>
                      ) : (
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={yearErr}
                          fullWidth
                        >
                          <InputLabel>Year</InputLabel>
                          <Select1
                            label="Year"
                            value={year}
                            onChange={(e) => handleYear(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Select Year</em>
                            </MenuItem>
                            {years &&
                              years.map((year) => (
                                <MenuItem value={year.id}>{year.name}</MenuItem>
                              ))}
                          </Select1>
                          <FormHelperText>{false}</FormHelperText>
                        </FormControl>
                      )}
                    </div>
                    <div className="form-group" style={{ height: 40 }}>
                      {aois.length == 0 ? (
                        <div className="text-center">
                            <img className="img-fluid" src="/sscLoading.gif" width="60%" />                         
                        </div>
                      ) : (
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                        >
                          <InputLabel>Area of Interest</InputLabel>
                          <Select1
                            label="Area of Interest"
                            value={aoi}
                            onChange={(e) => handleAoi(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Select Area of Interest</em>
                            </MenuItem>
                              <MenuItem
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                    value={2099999993}
                                  >
                                    {"OTHER"}
                              </MenuItem>
                            {aois &&
                              aois.map((aoi) => (
                                <MenuItem value={aoi.id}>{aoi.name}</MenuItem>
                              ))}
                          </Select1>
                          <FormHelperText>{false}</FormHelperText>
                        </FormControl>
                      )}
                    </div>
                    {otherAoiStatus == true ? (
                      <div className="form-group" style={{ height: 50 }}>
                        <LightTooltip title="Example: 8704524565">
                          <TextField
                            fullWidth
                            helperText={false}
                            variant="outlined"
                            margin="dense"
                            label="Other Aoi"
                            type="text"
                            value={otherAoi}
                            onChange={(e) => setOtherAoi(e.target.value)}
                          />
                        </LightTooltip>
                      </div>
                    ) : (
                      ""
                    )}                    
                    <div className="form-group" style={{ height: 40 }}>
                      <LightTooltip title="Example: Reading Novels, Writing Stories">
                        <TextField
                          fullWidth
                          helperText={false}
                          variant="outlined"
                          margin="dense"
                          label="Hobbies"
                          type="text"
                          value={hobbies}
                          onChange={(e) => setHobbies(e.target.value)}
                        />
                      </LightTooltip>
                    </div>
                    <div className="form-group" style={{ height: 40 }}>
                      <LightTooltip title="Example: How to become an entrepreneur?, How to build career in Medical?">
                        <TextField
                          fullWidth
                          helperText={false}
                          variant="outlined"
                          margin="dense"
                          label="Question"
                          type="text"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                      </LightTooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-5 text-center">
                {submit == true ? (
                    <div className="text-center">
                            <img className="img-fluid" src="/loading_new.gif" width="100px" />                       
                    </div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                )}
              </div>
              <hr />
              <div className="px-5 text-center">
                <div
                  style={{
                    fontSize: "14px",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  <p>
                    2<sup>nd</sup> Floor, Gypsy Building, Paona International
                    Market, Paona Bazar Moreh Market, Near Impact TV, Imphal,
                    Manipur 795001
                  </p>
                  <p>
                    <span>Contact No: 8414833817 or 7628960947</span>
                    <span> Email: sscsolution14@gmail.com</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="text-center">
              <h5 className="text-success">Registered Successfully</h5>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default App;
